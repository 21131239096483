/**
 * RemoteData is inspired by Elm-lang's RemoteData package.
 *
 * These types and values help represent our asynchronous data flow in
 * a stateful, explicit fashion.
 *
 * To learn more about why this pattern is great and prevents
 * weird loading states, check out:
 * http://blog.jenkster.com/2016/06/how-elm-slays-a-ui-antipattern.html
 */

/**
 * RemoteData related types and variables.
 */

const NOT_ASKED = "REMOTE_DATA::NOT_ASKED" as const;
const LOADING = "REMOTE_DATA::LOADING" as const;
const FAILURE = "REMOTE_DATA::FAILURE" as const;

/**
 * `RemoteData` of type `T` can be in 1 of 4 states.
 *
 * `NOT_ASKED`: a given RemoteData<T> hasn't been requested.
 *
 * `LOADING`: a given RemoteData<T> has been requested but has yet to receive
 * a value.
 *
 * `FAILURE`: a given RemoteData<T> has failed to be fetched (due to some
 * error on the server or client)
 *
 * `T`: If a resource was successfully fetched, the resulting type is exactly
 * of type `T`, and we can then consume it as we would like.
 */
export type RemoteData<T> =
  | typeof NOT_ASKED
  | typeof LOADING
  | typeof FAILURE
  | T;

export const RemoteData = {
  /**
   * This is the initial state for a given remote data. We have yet to
   * request for this data from the server.
   */
  NOT_ASKED,
  /**
   * The state where the remote data has been requested but the response
   * has yet to be received. We would want to render a loading indicator
   * in this case.
   */
  LOADING,
  /**
   * The state where the requested data was fetched unsuccessfully. An
   * example of this is when we receive a server error. We would then
   * display a user-facing error state on the page.
   */
  FAILURE,

  // Type guards to help type narrow variables of type RemoteData<T>

  isFailure: <T>(rd: RemoteData<T>): rd is typeof FAILURE => rd === FAILURE,
  isLoading: <T>(rd: RemoteData<T>): rd is typeof LOADING => rd === LOADING,
  isSettled: <T>(rd: RemoteData<T>): rd is T | typeof FAILURE =>
    rd !== NOT_ASKED && rd !== LOADING,
  isReady: <T>(rd: RemoteData<T>): rd is T =>
    RemoteData.isSettled(rd) && !RemoteData.isFailure(rd),
  all,
};

function all<T1, T2>(
  t1: RemoteData<T1>,
  t2: RemoteData<T2>
): RemoteData<[T1, T2]>;

function all<T>(...args: RemoteData<T>[]): RemoteData<T[]> {
  // If everything is ready, return the array as T[].
  if (args.every(RemoteData.isReady)) return args as T[];
  // If any of the data is in a failed state, return a single RemoteData.FAILURE.
  if (args.find(RemoteData.isFailure) !== undefined) return RemoteData.FAILURE;
  // if any of the data is in a loading state, return a single
  // RemoteData.LOADING.
  if (args.find(RemoteData.isLoading) !== undefined) return RemoteData.LOADING;
  // Something is not ready, so return a single RemoteData.NOT_ASKED.
  return RemoteData.NOT_ASKED;
}
