import React, { FC } from "react";

export const Flickr: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 291.319 291.319"
  >
    <path
      fill="#e4e7e7"
      d="M145.659,0c80.45,0,145.66,65.219,145.66,145.66c0,80.45-65.21,145.659-145.66,145.659
		S0,226.109,0,145.66C0,65.219,65.21,0,145.659,0z"
    />
    <path
      fill="#0063dc"
      d="M136.665,145.623c0,20.055-16.323,36.324-36.47,36.324s-36.47-16.268-36.47-36.324
		s16.323-36.333,36.47-36.333S136.665,125.568,136.665,145.623z"
    />
    <path
      fill="#ff0084"
      d="M227.702,145.623c0,20.055-16.323,36.324-36.47,36.324s-36.469-16.268-36.469-36.324
		s16.323-36.333,36.469-36.333S227.702,125.568,227.702,145.623z"
    />
  </svg>
);
