import anime from "animejs";
import React, {
  useEffect,
  FC,
  useState,
  Dispatch,
  SetStateAction,
  MouseEvent,
  Suspense,
} from "react";
import imagesLoaded from "imagesloaded";
import Masonry from "masonry-layout";
import Loader from "react-loader-spinner";

import { PhilipRudio } from "./PhilipRudio";
import { Facebook } from "./Facebook";
import { Flickr } from "./Flickr";
import { Instagram } from "./Instagram";
import { Linkedin } from "./Linkedin";
import * as config from "./config.json";
import { RemoteData } from "./RemoteData";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import "./App.css";

/**
 * Value that is used as tags for photos we want to use on the site. These
 * values are set on Flickr.
 */
const TAG = "usethis";

const FLICKR_ENDPOINT = "https://api.flickr.com/services/rest/";
const API_KEY = config.key;
const USER_ID = config.user_id;
const FLICKR_SEARCH_PHOTOS_BASE_URL =
  FLICKR_ENDPOINT + "?method=flickr.photos.search&format=json";

/**
 * We would filter the results by passing a 'tags' param but the Flickr search
 * api produces inconsistent results...
 */
const getPhotosUrlBatch1 =
  FLICKR_SEARCH_PHOTOS_BASE_URL +
  "&api_key=" +
  API_KEY +
  "&user_id=" +
  USER_ID +
  "&per_page=1000" +
  // Extra properties of each photo we want to receive from each photo record.
  "&extras=tags,url_l" +
  "&nojsoncallback=1";

const getPhotosUrlBatch2 =
  FLICKR_SEARCH_PHOTOS_BASE_URL +
  "&api_key=" +
  API_KEY +
  "&user_id=" +
  USER_ID +
  "&extras=tags" +
  "&per_page=1000" +
  "&page=2" +
  "&nojsoncallback=1";

interface ResponseResult {
  photos: {
    page: number;
    pages: number;
    perpage: number;
    photo: RemotePhoto[];
    /** String representation of the number of photo results */
    total: string;
  };
  stat: "ok" | "fail";
}

interface ImagesByUrl {
  [url: string]: JSX.Element;
}

interface SocialMediaInfo {
  url: string;
  icon: FC;
  tooltip: string;
}

interface RemotePhoto {
  url: string;
  farm: number;
  id: string;
  isfamily: number;
  isfriend: number;
  ispublic: number;
  owner: string;
  secret: string;
  server: string;
  title: string;
  tags: string;
  url_l: string;
}

const Loading: FC = () => <>Loading</>;

const createImageUrl = (p: RemotePhoto) =>
  p.url_l ||
  `https://farm${p.farm}.staticflickr.com/${p.server}/${p.id}_${p.secret}.jpg`;

const App: FC = () => {
  return (
    <div className="App">
      <Header />
      <Suspense fallback={Loading}>
        <Page />
      </Suspense>
    </div>
  );
};

const AnimatedLogo: FC<{ logo: FC }> = ({ logo }) => {
  useEffect(() => {
    anime({
      targets: ".philiprudio path",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 2000,
      delay: 300,
      direction: "alternate",
      loop: false,
      stroke: "#ffffff",
    });
  });

  return <>{logo({}, {})}</>;
};

const Page: FC = () => {
  const [showOverlay, setShowOverlay] = useState<string>("");
  const [remotePhotos, setRemotePhotos] = useState<RemoteData<ImagesByUrl>>(
    "REMOTE_DATA::LOADING"
  );
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    // fetch photos from flickr
    (async () => {
      const requestBatch1 = fetch(getPhotosUrlBatch1).then((response) =>
        response.json()
      );
      const requestBatch2 = fetch(getPhotosUrlBatch2).then((response) =>
        response.json()
      );

      try {
        const remotePhotos = await Promise.all([
          requestBatch1,
          requestBatch2,
        ]).then((responses) =>
          responses.flatMap((response: ResponseResult) => response.photos.photo)
        );
        const filteredPhotoList = remotePhotos.filter((p) =>
          p.tags.includes(TAG)
        );

        const urls = filteredPhotoList.map(createImageUrl);

        const imagesByUrl = urls.reduce<ImagesByUrl>((memo, url, index) => {
          memo[url] = (
            <img
              key={url}
              className={"c-grid__item " + (index === 0 ? "c-grid__sizer" : "")}
              alt="todo"
              src={url}
              onLoad={() => setCount(count + 1)}
              onClick={(_) => {
                setShowOverlay(url);
              }}
            />
          );
          return memo;
        }, {});
        setRemotePhotos(imagesByUrl);
      } catch (error) {
        console.error(error);
        setRemotePhotos("REMOTE_DATA::FAILURE");
      }
    })();
  }, [count, setShowOverlay]);

  const overlayValue =
    showOverlay &&
    remotePhotos &&
    RemoteData.isReady(remotePhotos) &&
    remotePhotos[showOverlay]
      ? remotePhotos[showOverlay]
      : null;

  return (
    <>
      <div className="c-content">
        <section>
          {RemoteData.isLoading(remotePhotos) ? (
            <>
              <Loader
                type="Rings"
                color="#ffffff"
                height={100}
                width={100}
                timeout={3000}
              />
            </>
          ) : RemoteData.isFailure(remotePhotos) ? (
            <>
              Sorry! Something went wrong while trying to fetch Philip's photos.
              Please try again another time =)
            </>
          ) : RemoteData.isReady(remotePhotos) ? (
            <PhotoGrid
              photos={
                RemoteData.isReady(remotePhotos)
                  ? Object.values(remotePhotos)
                  : undefined
              }
            />
          ) : (
            <>
              Sorry! Not really sure what's going on. Please try reloading the
              page!
            </>
          )}
        </section>
      </div>
      <FooterContainer
        remotePhotos={remotePhotos}
        overlayValue={overlayValue}
        setShowOverlay={setShowOverlay}
      />
    </>
  );
};

const FooterContainer: FC<{
  overlayValue: JSX.Element | null;
  remotePhotos: RemoteData<ImagesByUrl>;
  setShowOverlay: Dispatch<SetStateAction<string>>;
}> = ({ remotePhotos, overlayValue, setShowOverlay }) => (
  <>
    {false ? (
      <div
        className={`c-overlay ${overlayValue ? "" : "c-overlay--hidden"}`}
        onClick={() => setShowOverlay("")}
      >
        {overlayValue}
        OVERLAY
      </div>
    ) : null}
    {RemoteData.isReady(remotePhotos) ? <Footer /> : null}
  </>
);

const Header: FC = () => (
  <header className="c-header">
    <h1 className="c-header__name" title="it me =)">
      <AnimatedLogo logo={PhilipRudio} />
    </h1>
    <ul className="c-header__social">
      {Object.values(SocialMediaInfoByName).map((info) => (
        <SocialMediaItem key={info.url} info={info} />
      ))}
    </ul>
  </header>
);

const SocialMediaInfoByName: { [name: string]: SocialMediaInfo } = {
  flickr: {
    url: "https://www.flickr.com/photos/146518845@N05",
    icon: Flickr,
    tooltip: "Flickr",
  },
  facebook: {
    url: "http://facebook.com/philip.rudio.7",
    icon: Facebook,
    tooltip: "Facebook",
  },
  instagram: {
    url: "http://instagram.com/iamphiliprudio/?hl=en",
    icon: Instagram,
    tooltip: "Instagram",
  },
  linkedin: {
    url: "http://linkedin.com/in/philiprudio",
    icon: Linkedin,
    tooltip: "Linkedin",
  },
};

const highlightItem = (item: MouseEvent): void => {
  // TODO: implement
};

const SocialMediaItem: FC<{ info: SocialMediaInfo }> = ({ info }) => (
  <li className="c-header__social__item">
    <a
      href={info.url}
      rel="noopener noreferrer"
      target="_blank"
      title={info.tooltip}
      onMouseOver={highlightItem}
    >
      {info.icon({})}
    </a>
  </li>
);

const PhotoGrid: FC<{ photos: JSX.Element[] | undefined }> = ({
  photos: remotePhotos,
}) => {
  useEffect(() => {
    setTimeout(() => {
      const imgLoad = imagesLoaded(".c-grid");
      imgLoad.on("progress", (instance, image) => {
        if (image === undefined || !image.isLoaded) {
          // This image could not be loaded. Error out
          console.error("could not load image: ", image);
          throw new Error("could not load image");
        }
        new Masonry(".c-grid", {
          columnWidth: ".c-grid__sizer",
          itemSelector: ".c-grid__item",
          // This should match margin-bottom with 'c-grid__item'
          gutter: 5,
        });
      });
    }, 100);
  }, [remotePhotos]);

  return (
    <>
      <div className="c-grid">
        {remotePhotos ? remotePhotos.map((photo) => photo) : null}
      </div>
      {/* <div className="c-image-container--hidden">
        {remotePhotos ? remotePhotos.map((photo) => photo) : null}
      </div> */}
    </>
  );
};

const Footer: FC = () => (
  <div className="c-footer">
    &copy; 2020 Philip Rudio - Seattle Based Photographer
  </div>
);

export default App;
