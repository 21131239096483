import React, { FC } from "react";

export const PhilipRudio: FC = () => (
  <svg className="philiprudio" x="0px" y="0px" viewBox="0 0 250 100">
    <g fill="#34515e" stroke="#34515e">
      {/* <g fill="#ffffff" stroke="#ffffff"> */}
      <path
        d="M7.96,17.93c10.78,0,15.22,5.81,15.22,19.09c0,12.69-5.42,20.73-15.51,20.73H7.21v23.06c0,0.87-0.4,1.55-0.92,1.55H5.37
			c-0.52,0-0.92-0.68-0.92-1.55V19.38c0-0.78,0.4-1.45,0.92-1.45H7.96z M7.67,53.39c7.49,0,12.91-4.85,12.91-16.38
			c0-11.92-4.44-14.73-13.37-14.73v31.1H7.67z"
      />
      <path
        d="M32.4,48.94c1.5-3.39,4.09-5.14,6.69-5.14c5.07,0,8.82,6.69,8.82,14.82v22.19c0,0.87-0.4,1.55-0.92,1.55h-0.92
			c-0.52,0-0.92-0.68-0.92-1.55V58.63c0-5.62-2.82-10.17-6.34-10.17c-3.46,0-6.4,4.36-6.4,10.17v22.19c0,0.87-0.4,1.55-0.92,1.55
			h-0.92c-0.46,0-0.92-0.68-0.92-1.55V19.38c0-0.78,0.46-1.45,0.92-1.45h0.92c0.52,0,0.92,0.68,0.92,1.45V48.94z"
      />
      <path
        d="M57.31,27.72c0.52,0,0.92,0.68,0.92,1.55v5.72c0,0.87-0.4,1.55-0.92,1.55h-0.92c-0.52,0-0.92-0.68-0.92-1.55v-5.72
			c0-0.87,0.4-1.55,0.92-1.55H57.31z M57.31,45.16c0.52,0,0.92,0.68,0.92,1.55v34.11c0,0.87-0.4,1.55-0.92,1.55h-0.92
			c-0.52,0-0.92-0.68-0.92-1.55V46.71c0-0.87,0.4-1.55,0.92-1.55H57.31z"
      />
      <path
        d="M68.03,17.93c0.46,0,0.86,0.68,0.86,1.45v61.43c0,0.87-0.4,1.55-0.86,1.55h-0.98c-0.46,0-0.86-0.68-0.86-1.55V19.38
			c0-0.78,0.4-1.45,0.86-1.45H68.03z"
      />
      <path
        d="M78.7,27.72c0.52,0,0.92,0.68,0.92,1.55v5.72c0,0.87-0.4,1.55-0.92,1.55h-0.92c-0.52,0-0.92-0.68-0.92-1.55v-5.72
			c0-0.87,0.4-1.55,0.92-1.55H78.7z M78.7,45.16c0.52,0,0.92,0.68,0.92,1.55v34.11c0,0.87-0.4,1.55-0.92,1.55h-0.92
			c-0.52,0-0.92-0.68-0.92-1.55V46.71c0-0.87,0.4-1.55,0.92-1.55H78.7z"
      />
      <path
        d="M90.23,50.87c2.08-3.88,4.84-6.98,8.94-6.98c6.51,0,11.76,8.82,11.76,19.67c0,10.85-5.25,19.67-11.76,19.67
			c-3.92,0-6.92-3.29-8.94-6.98v22c0,0.87-0.4,1.55-0.92,1.55h-0.92c-0.52,0-0.92-0.68-0.92-1.55V46.71c0-0.87,0.4-1.55,0.86-1.55
			c0.4,0,0.75,0.39,0.86,1.07L90.23,50.87z M99.16,48.55c-4.84,0-8.94,6.49-8.94,15.02c0,7.46,3.63,15.02,8.94,15.02
			c5.19,0,8.99-6.88,8.99-15.02C108.15,55.04,103.89,48.55,99.16,48.55z"
      />
      <path
        d="M152.77,79.94c0.58,0.97,0.06,2.42-0.69,2.42h-1.33c-0.35,0-0.58-0.29-0.75-0.58l-14.12-26.06
			c-0.46-0.97-0.06-2.62,0.75-2.62c6.8,0,12.28-4.84,12.28-16.08c0-12.4-5.71-14.53-10.67-14.53h-2.59v58.33
			c0,0.87-0.4,1.55-0.92,1.55h-0.92c-0.52,0-0.92-0.68-0.92-1.55V19.38c0-0.78,0.4-1.45,0.92-1.45h4.44
			c7.61,0,13.37,5.23,13.37,19.09c0,10.76-3.86,18.12-11.18,20.15l12.28,22.67L152.77,79.94z"
      />
      <path
        d="M176.41,45.16c0.52,0,0.92,0.68,0.92,1.55v34.11c0,0.87-0.4,1.55-0.92,1.55c-0.46,0-0.75-0.58-0.87-1.16l-0.75-3.49
			c-1.38,2.71-3.69,5.52-6.8,5.52c-5.19,0-9.34-5.33-9.34-14.82v-21.7c0-0.87,0.4-1.55,0.87-1.55h0.98c0.46,0,0.87,0.68,0.87,1.55
			v21.7c0,6.49,2.71,10.17,6.63,10.17c4.38,0,6.57-6.69,6.57-12.69V46.71c0-0.87,0.4-1.55,0.92-1.55H176.41z"
      />
      <path
        d="M206.67,17.93c0.52,0,0.92,0.68,0.92,1.45v61.43c0,0.87-0.4,1.55-0.92,1.55c-0.35,0-0.75-0.48-0.86-1.16l-0.98-5.04
			c-1.9,3.78-4.96,7.07-8.94,7.07c-6.51,0-11.7-8.82-11.7-19.67c0-10.95,5.19-19.77,11.7-19.77c4.09,0,7.15,3.58,8.94,7.07V19.38
			c0-0.78,0.4-1.45,0.92-1.45H206.67z M186.9,63.57c0,8.53,4.27,15.02,8.99,15.02c5.53,0,8.94-7.75,8.94-15.02
			c0-8.92-4.32-15.12-8.94-15.12C190.94,48.45,186.9,55.23,186.9,63.57z"
      />
      <path
        d="M217.28,27.72c0.52,0,0.92,0.68,0.92,1.55v5.72c0,0.87-0.4,1.55-0.92,1.55h-0.92c-0.52,0-0.92-0.68-0.92-1.55v-5.72
			c0-0.87,0.4-1.55,0.92-1.55H217.28z M217.28,45.16c0.52,0,0.92,0.68,0.92,1.55v34.11c0,0.87-0.4,1.55-0.92,1.55h-0.92
			c-0.52,0-0.92-0.68-0.92-1.55V46.71c0-0.87,0.4-1.55,0.92-1.55H217.28z"
      />
      <path
        d="M247.55,63.95c0,10.17-4.84,19.67-11.7,19.67c-6.86,0-11.7-9.5-11.7-19.67c0-10.85,5.25-19.67,11.7-19.67
			C242.3,44.29,247.55,53.1,247.55,63.95z M226.74,63.95c0,8.14,4.04,15.31,9.11,15.31c4.67,0,9.11-5.91,9.11-15.31
			c0-8.33-4.09-15.31-9.11-15.31C230.89,48.65,226.74,55.62,226.74,63.95z"
      />
    </g>
  </svg>
);
